<template>
  <div id="player" :class="{'build-container':build, 'mobile':$vuetify.breakpoint.smAndDown}" v-if="!$isEmpty(video)">
    <!-- customEventName="customstatechangedeventname"
    @waiting="onPlayerWaiting($event)"
    @playing="onPlayerPlaying($event)"
    @loadeddata="onPlayerLoadeddata($event)"
    @canplay="onPlayerCanplay($event)"
    @canplaythrough="onPlayerCanplaythrough($event)"
    @play="onPlayerPlay($event)"
   -->

   <!-- WATCH & EDIT: Spacebar makes the video play and pause -->
    <GlobalEvents
     v-if="listenersConnected"
     :filter="(event, handler, eventName) => event.target.tagName !== 'INPUT'"
     @keydown="logEvent($event)"
    />

   <!-- EDIT: controls for saving and publishing the video -->
   <video-save v-if="build"
      :autoSaved="autoSaved"
      :videoId="video['.key']"
      @toggleAutoSave="toggleAutoSave"
      @publishVideo="publishVideo"
      @saveNow="postStepUpdates">
    </video-save>

    <div class="player-container">
      <video-player  class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        @ready="playerReadied"
        @play="onPlayerPlay($event)"
        @playing="onPlayerPlaying($event)"
        @pause="onPlayerPause($event)"
        @ended="onPlayerEnded($event)"
        @statechanged="playerStateChanged($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @onError="onError($event)"
        >
      </video-player>

      <!-- WATCH: forward and backward buttons -->
      <stepper v-if="!build && $vuetify.breakpoint.mdAndUp"
        :steps="video.steps"
        :skipIntro="video.skipIntro"
        :currentMarker="currentMarker"
        :timeIn="playerCurrentState"
        :isPlaying="isPlaying"
        :duration="video.duration"
        @skipIntro="onStepClicked({step: video.steps[1], origin: 'skipIntro'})"
        @onStepClicked="onStepClicked"
        @startPlaying="startPlaying">
      </stepper>
      <stepper-mobile v-if="!build && $vuetify.breakpoint.smAndDown"
        :steps="video.steps"
        :skipIntro="video.skipIntro"
        :currentMarker="currentMarker"
        :timeIn="playerCurrentState"
        :isPlaying="isPlaying"
        :duration="video.duration"
        @skipIntro="cuePlayer(video.steps[1].timestamp)"
        @onStepClicked="onStepClicked"
        @startPlaying="startPlaying">
      </stepper-mobile>

      <video-controls v-if="build"
        :timeIn="playerCurrentState"
        :duration="video.duration"
        :video="video"
        @onStepChange="onStepChange"
        @updateVideoTime="updateVideoTime">
      </video-controls>
      <video-details :build="build" :video="video"></video-details>
</div>

    <div class="steps-container" :class="{'gradientBG' : !build, 'grey lighten-2' : build}">
      <!-- WATCH: step counter -->
      <wizard v-if="!build" :stepView="video.stepView" :markers="video.steps" :currentMarker="currentMarker" :isPlaying="isPlaying" @onStepClicked="onStepClicked" @startPlaying="startPlaying" @toggleView="toggleView"></wizard>

      <!-- WATCH: Type of steps -->
      <step v-if="toggle_view == 'step' && !build && video.steps.length > 0" :step="video.steps[currentMarker.index]" :currentMarker="currentMarker" :isPlaying="isPlaying" @onStepClicked="onStepClicked"></step>
      <steps v-if="toggle_view == 'steps' && !build && video.steps.length > 0" :steps="video.steps" :currentMarker="currentMarker" :isPlaying="isPlaying" @onStepClicked="onStepClicked"></steps>

      <!-- WATCH: voice control -->
      <vox v-if="!build && $vuetify.breakpoint.mdAndUp" :markers="video.steps" :currentMarker="currentMarker" :isPlaying="isPlaying" :micOn="micOn" @voxCommands="voxCommands"></vox>

      <!-- EDIT: create step bar -->
      <step-builder v-if="build"
        :steps="video.steps"
        :published="video.published"
        :stepView="video.stepView"
        :skipIntro="video.skipIntro"
        :duration="video.duration"
        :currentMarker="currentMarker"
        :timeIn="playerCurrentState"
        @onStepClicked="onStepClicked"
        @onStepChange="onStepChange"
        @updateVideoTime="updateVideoTime">
      </step-builder>

    </div>

    <template v-if="user !== undefined">
      <!-- <welcome-create-modal v-if="user.showCreateTour && build"></welcome-create-modal> -->
      <!-- <welcome-watch-modal v-if="user.showWatchTour && !build"></welcome-watch-modal> -->
      <edit-tour v-if="user.showCreateTour && build"></edit-tour>
    </template>

    <v-dialog v-model="errorDialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Whooopsie...
        </v-card-title>

        <v-card-text>
          <p class="pt-2">Something went wrong on your internet, Youtube, or gremlins maybe.</p>
          <p>Or it could be a <a href="https://www.reddit.com/r/Adblock/comments/fn2cka/adblock_broken_on_youtube_desktop_firefox_chrome/" target="_blank">recent issue</a> with <strong>AdBlock</strong> extension. So you could temporarily disable that and reload the page.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="errorDialog = false"
          >
           Ahhh, ok I see.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



  </div>

</template>

<script>
// require styles
// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
import 'videojs-markers/dist/videojs.markers.css'
// import VueVideoPlayer from "vue-video-player"
import { videoPlayer } from 'vue-video-player'

// if youtube plugin isn't working
// https://github.com/spchuang/videojs-markers/issues/69#issuecomment-337088714
import 'videojs-youtube'
import 'videojs-markers'

// import instructJSON from '@/components/json/instructions.json'
// import youtorialJSON from '@/components/json/youtorial-steps.json'
const stepbuilderCard = () => import('@/components/shared/stepbuilder-cards.vue')
const steps = () => import('@/components/steps.vue')
const step = () => import('@/components/step.vue')
const wizard = () => import('@/components/wizard.vue')
const stepper = () => import('@/components/stepper.vue')
const stepperMobile = () => import('@/components/stepper-mobile.vue')
const stepBuilder = () => import('@/components/step-builder.vue')
const videoControls = () => import('@/components/video-controls.vue')
const videoSave = () => import('@/components/video-save.vue')
const videoDetails = () => import('@/components/video-details.vue')
const vox = () => import('@/components/vox.vue')
const editTour = () => import('@/components/tour/edit.vue')
const welcomeCreateModal = () => import('@/components/shared/welcome-create-modal.vue')
const welcomeWatchModal = () => import('@/components/shared/welcome-watch-modal.vue')

import GlobalEvents from 'vue-global-events'

import { db } from '@/firebase';

export default {
  name: "player",
  props: ['build', 'user','userId', 'video'],
  components: {
    videoPlayer,
    editTour,
    // instructJSON,
    // youtorialJSON,
    step,
    steps,
    wizard,
    stepper,
    stepperMobile,
    stepBuilder,
    videoControls,
    videoSave,
    videoDetails,
    GlobalEvents,
    vox,
    welcomeCreateModal,
    welcomeWatchModal,
  },

  data(){
    return{
      // youtorialSTEPS: youtorialJSON,
      showTutorialModal:false,
      sentence:"",
      statement:"",
      micOn: false,
      currentMarker:{},
      lastClicked: {},
      playerCurrentState:"",
      isPlaying: false,
      buildVideoLoaded: false,
      player: null,
      markers:[],
      skipIntro: true,
      toggle_view: null,
      toggle_voice: 0,
      voiceCommand: false,
      listenersConnected: false,
      stepClicked: false,
      autoSaved: false,
      canPublishVideo: false,
      autoSaveTimer: null,
      snackbarTimer: null,
      errorDialog: false,
      // autoPause: true,
      playerOptions:{
        aspectRatio: "16:9",
        language: 'en',
        // loadingSpinner: this.build ? false : true,
        preload: this.build ? 'auto' : 'none',
        autoplay: this.build ? true : false,
        controlBar:{
          height: 60,
          nativeControlsForTouch: this.$vuetify.breakpoint.smAndDown,
          // fullscreenToggle: {options: {navigationUI: 'hide'}},
          fullscreenToggle:false,
        },
        poster: this.video.thumbnail_large,
        techOrder: ["youtube"],
          // playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/youtube",
            src: `http://www.youtube.com/watch?v=${this.video.videoId}`
          }],
          // poster: this.video.thumbnail_large,
          youtube: {
            ytControls: 0,
            modestbranding: 1,
            playsinline: 1,
            customVars: {
              wmode: 'transparent',
            },
            origin: process.env.NODE_ENV === "development" ? 'http://localhost:8080' : 'https://watchyoutorials.com/',
            widget_referrer:process.env.NODE_ENV === "development" ? 'http://localhost:8080' : 'https://watchyoutorials.com/',
            showinfo: 0,
            rel: 0,
            ecver:2
          }
      },
      commands:{
        previous:[
          'previous', 'replay','rewind', 'backwards'
        ],
        play:[
          ' play', 'start', 'next', 'continue', 'keep going', 'proceed'
        ],
        skip:[
          'skip', 'skip it', 'skip this step', 'skip this next step', 'next', 'next step', 'move on', 'move forward', 'move past this one'
        ]
      }
    }
  },
  // watch:{
  //   video:{
  //     handler: function (val, oldVal) {
  //       process.env.NODE_ENV === "development" ? console.log( 'val from video', val ) : null;
  //       // Return the object that changed
  //         this.stepList = val.steps;
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    startPlaying(){
      this.$refs.videoPlayer.player.play();
    },
    toggleView(data){
      this.toggle_view = data;
    },
    voxCommands(data){
      let vThis = this;
      process.env.NODE_ENV === "development" ? console.log( 'data from vox', data ) : null;
      if(data == 'play'){
        this.$refs.videoPlayer.player.play();
        this.statement = 'play';
        process.env.NODE_ENV === "development" ? console.log( 'found play' ) : null;
      }
      if(data == 'previous'){
        let marker = vThis.currentMarker.index-1
        process.env.NODE_ENV === "development" ? console.log( 'previous is...', marker ) : null;
        if(marker >= 0){
          this.voiceCommand = true;
          this.statement = 'previous step';
          this.cuePlayer(vThis.stepList[marker].timestamp, true)
          process.env.NODE_ENV === "development" ? console.log( 'found previous' ) : null;
        }
      }
      if(data == 'skip'){
        vThis.lastClicked.time = vThis.currentMarker.time;
        this.statement = 'skip step';
        this.cuePlayer(vThis.stepList[vThis.currentMarker.index+1].timestamp)
        process.env.NODE_ENV === "development" ? console.log( 'found skip' ) : null;
      }
    },
     // listen event
     onPlayerPlay(player) {
       process.env.NODE_ENV === "development" ? console.log( 'onPlayerPlay', player ) : null;
       player.markers.updateTime();
       this.isPlaying = true;
       this.micOn = false;
       this.statement = "";
       this.voiceCommand = false;
       if(player.currentTime() < 1 && !this.build){
         process.env.NODE_ENV === "development" ? console.log( 'log waching event' ) : null;
         this.$logEvent('Video_started', {
           'event_category':`${this.video.videoId}`,
           'event_label':`${this.video['.key']}`,
           'seconds_duration':this.$moment.duration(this.video.duration).asSeconds()
         });
       }
     },
     onPlayerPlaying(player){
       process.env.NODE_ENV === "development" ? console.log( 'onPlayerPlaying', player ) : null;
       if ((player.currentTime() <= 1 && this.build) && !this.buildVideoLoaded) {
         player.pause();
         this.buildVideoLoaded = true;
       }
     },
     onPlayerPause(player) {
       process.env.NODE_ENV === "development" ? console.log( 'player pause!', player ) : null;
       this.isPlaying = false;
       this.micOn = true;
       this.voiceCommand = false;
     },
     onPlayerEnded(player){
       process.env.NODE_ENV === "development" ? console.log( 'video ended' ) : null;
       this.$logEvent('Video_watched', {
         'event_category':`${this.video.videoId}`,
         'event_label':`${this.video['.key']}`,
         'seconds_watched':this.$moment.duration(this.video.duration).asSeconds()
       });
     },
     onError(player){
      process.env.NODE_ENV === "development" ? console.log( 'error', player ) : null;
     },
     // ...player event
     // or listen state event
     playerStateChanged(playerCurrentState) {
       // process.env.NODE_ENV === "development" ? console.log( 'player current update state', playerCurrentState ) : null;

       this.playerCurrentState = playerCurrentState;
     },
     onPlayerTimeupdate(e){
       // console.log('timeer update', e.currentTime());
     },
     logEvent(event){
       // console.log('event', event);
       if((event.code === 'ArrowLeft' || event.code === 'ArrowRight' || event.code === 'Space') && !event.target.className.includes('trumbowyg-editor')){
         event.preventDefault();
         if(event.code === 'Space'){
           this.togglePlayer(event);
         }
         if(event.code === 'ArrowUp' || event.code === 'ArrowDown'){
           let step = {
             step: this.stepList[this.currentMarker.index],
             element:`#step_${this.currentMarker.index}`
           }
           let data = {step: step, origin: 'keyboard_replay'}
           this.onStepClicked(data)
         }
         if(event.code === 'ArrowLeft'){
           let step = {
             step: this.currentMarker.index == 0 ? this.stepList[0] : this.stepList[this.currentMarker.index-1],
             element:`#step_${this.currentMarker.index}`
           }
           let data = {step: step, origin: 'keyboard_rewind'}
           this.onStepClicked(data)
         }
         if(event.code === 'ArrowRight'){
           if(this.currentMarker.index < this.stepList.length ){
             let step = {
               step: this.stepList[this.currentMarker.index+1],
               element:`#step_${this.currentMarker.index}`
             }
             let data = {step: step, origin: 'keyboard_forward'}
             this.onStepClicked(data)
           }
         }
       }
       else if(event.code === 'KeyS' && ( event.ctrlKey === true || event.altKey === true || event.code === "MetaLeft" || event.code === "MetaRight") ){
         event.preventDefault();
         this.postStepUpdates()
       }
     },
     onStepClicked(m, origin){
       let vThis = this;
       let orginVal = origin === true ? origin : m.origin
       process.env.NODE_ENV === "development" ? console.log( 'onStepClicked', m ,orginVal) : null;
       // this.$logEvent('Controlled', {
       //   'event_category':`${this.video.videoId}`,
       //   'event_label': ,
       //   'seconds_duration':this.$moment.duration(this.video.duration).asSeconds()
       // });
       vThis.cuePlayer(m.step.timestamp, m.element)
     },
     onStepChange(data){
       process.env.NODE_ENV === "development" ? console.log( 'step data change', data ) : null;
       //player.markers.add([{ time: 40, text: "I'm added"}]);
       // player.markers.remove([1,2]);
       // vThis.$refs.videoPlayer.player
       this.markerBuilder(data, 'reset')
     },
     updateVideoTime(data){
      process.env.NODE_ENV === "development" ? console.log( 'updateVideoTime', data ) : null;
      this.$refs.videoPlayer.player.currentTime(data);
     },
     cuePlayer(timeStamp, el){
       let vThis = this;
       //prevent marker reached
       process.env.NODE_ENV === "development" ? console.log( 'player time', timeStamp ) : null;
       vThis.$refs.videoPlayer.player.currentTime(timeStamp);
       vThis.stepClicked = true;
       setTimeout(function(){
         vThis.$refs.videoPlayer.player.play();
         //scroll the bubble to the autoPause
         if(vThis.toggle_view == 'steps'){
           vThis.$scrollTo(el, '1s', {container:'#steps-scroll', easing: 'ease-out'});
         }
       }, 10);
     },
     // player is ready
     playerReadied(player) {
       let vThis = this;
       // this.stepList = instructJSON;
       vThis.listenersConnected = true;
       vThis.micOn = true;
       vThis.currentMarker = {'step':this.markers[0], 'index':0}

       player.load();

       player.on('error', (event)=>{
         process.env.NODE_ENV === "development" ? console.log( 'error on load', event ) : null;
         vThis.errorDialog = true;
       });

       player.markers({
         markerStyle: {
           'margin-left':'-7.5px',
           'width': '15px',
           'height': '0',
           'background-color': 'transparent',
           'border-style': 'solid',
           'border-width': '15px 7.5px 0 7.5px',
           'border-color': '#b468a2 transparent transparent transparent',
         },
         markerTip:{
            display: true,
            text: function(marker) {
               return marker.text;
            }
         },
         markers: this.markers,
         onMarkerReached: function(marker, index){
           process.env.NODE_ENV === "development" ? console.log( 'marker reached', marker, index ) : null;
           vThis.currentMarker = {'step':marker, 'index':index};
           //if autoPause is not enabled
           if(index > 0){
             if (vThis.video.steps[index].autopause == true){
                 if(vThis.voiceCommand){
                   player.play();
                   //reset
                   vThis.voiceCommand = false;
                 }
                 //prevent it pausing on the step clicked, weird bug i guess
                 else if(vThis.stepClicked){
                   setTimeout(function(){
                     player.play();
                     //reset
                     vThis.stepClicked = false;
                   }, 10);
                 }
                 else{
                   process.env.NODE_ENV === "development" ? console.log( 'marker reached: PAUSE' ) : null;
                   player.pause()
                 }
             }
             else{
               process.env.NODE_ENV === "development" ? console.log( 'not true marker' ) : null;
               vThis.stepClicked = false;
               vThis.voiceCommand = false;
             }
           }
         },
         onMarkerClick: function(marker){
           // process.env.NODE_ENV === "development" ? console.log( 'marker clicked', marker.time, vThis.markers.findIndex(el => marker.time == el.time), vThis.markers ) : null;
           vThis.currentMarker = {'step':marker, 'index': vThis.markers.findIndex(el => marker.time == el.time)};
           //hilarious offset since both functions fire at the same time
           setTimeout(function(){
             player.play()
           }, 10);
         }
       });
     },
    updateStep(data){
      process.env.NODE_ENV === "development" ? console.log( 'updateStep', data ) : null;
    },
    toggleAutoSave(data){
      process.env.NODE_ENV === "development" ? console.log( 'toggleAutoSave', data ) : null;
      if(data === true){
        this.autoSave();
      }
    },
    clearIntervals(){
      clearInterval(this.autoSaveTimer);
      clearInterval(this.snackbarTimer);
    },
    autoSave(){
      let vThis = this;
      vThis.autoSaveTimer = setInterval(() => {
        // method to be executed;
        vThis.postStepUpdates();
      }, 30000);
    },
    toggleSkipIntro(data){
      this.skipIntro = data;
    },
    publishVideo(data){
      this.canPublishVideo = true;
      this.postStepUpdates('publish');
    },
    postStepUpdates(isPublished){
      process.env.NODE_ENV === "development" ? console.log( 'is published?', isPublished ) : null;
      let vThis = this;
      let stepArray = this.stepList.slice();
      // let fullObject = this.youtorialSTEPS
      vThis.autoSaved = 'inprogress';
      // db.collection('videos').doc(this.video.videoId).update(
      db.collection('videos').doc(this.video['.key']).update({
        skipIntro:vThis.skipIntro,
        stepView: vThis.toggle_view,
        published: vThis.canPublishVideo,
        steps:stepArray
     }).then(data=>{
       if(isPublished == 'publish'){
         vThis.$router.push({name:'watch', params: {videoId: vThis.video['.key']}});
       }
       else{
         vThis.autoSaved = 'success';
         vThis.snackbar ={
           'show': true,
           'message': 'Youtorial Saved',
           'color':'success',
           'action': 'close'
         }
         vThis.snackbarTimer = setTimeout(() => {
           vThis.snackbar.show = false;
           vThis.autoSaved = 'done';
         },5000)
       }
     })
   },
   togglePlayer(event, skip){
     process.env.NODE_ENV === "development" ? console.log( 'event', event.target.className, event ) : null;
     if(!event.target.className.includes('trumbowyg-editor') || skip){
       if(this.isPlaying){
         this.$refs.videoPlayer.player.pause()
       }
       else{
         // this.stepClicked = true;
         this.$refs.videoPlayer.player.play();
       }
     }
   },
   markerBuilder(custom, reset){
     // console.log(custom, reset);
    let vThis = this;
    if(reset != undefined){
      vThis.markers = [];
      vThis.$refs.videoPlayer.player.markers.reset([]);
      custom.forEach((v,i) => {
        vThis.$refs.videoPlayer.player.markers.add([{'time': v.timestamp, 'text': v.title ? v.title : `Step${i+1}`}])
        vThis.markers.push({'time': v.timestamp, 'text': v.title ? v.title : `Step${i+1}`})
      });
    }
    else{
      vThis.video.steps.forEach((v,i) => {
        vThis.markers.push({'time': v.timestamp, 'text': v.title ? v.title : `Step${i+1}`})
        // vThis.$refs.videoPlayer.player.markers.add([{'time': v.timestamp, 'text': v.title ? v.title : `Step${i+1}`}])
      });
    }
   },
 },
 mounted(){

   this.markerBuilder(this.video.steps);
 },
 beforeDestroy () {
   this.clearIntervals();
   this.$refs.videoPlayer.dispose();
 }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.flex-auto{
  flex: 0 1 auto!important;
}
  #player{
      display: grid;
      grid-template-rows:1fr;
      grid-template-columns: 8fr 4fr;
      grid-template-areas:
      "video steps";
      width: 100%;
      height: 100%;
      &.build-container{
        grid-template-rows: 64px 1fr!important;
        grid-template-columns: 7fr 5fr!important;
        grid-template-areas:
        "controls controls"
        "video    steps";
        #video-details{
          padding-top: 15px;
          padding-left: 10px;
        }
      }
    #video-save{
      grid-area: controls;
    }
    .player-container{
      grid-area: video;
      position: relative;
      background: #f2f2f2;
      height: 100%;
      overflow: scroll;
    }
    .steps-container{
      max-height: 100%;
      overflow:hidden;
      grid-area: steps;
    }
    &.mobile{
      .steps-container{
        max-height:none;
      }
      .player-container{
        height: auto;
        overflow: auto;
      }
    }

    .madeWithGiphy{
      max-width: 100%;
      position: relative;
      img{
        max-width: 100%;
      }
      &:after{
        position: absolute;
        content: '';
        bottom: 10px;
        left: 6px;
        display: block;
        height: 15px;
        width: 65px;
        z-index: 999;
        background-image: url("~@/assets/giphy.svg");
      }
    }



    @media #{map-get($display-breakpoints, 'sm-and-down')}{

      display: block;

    }


    .video-player-box{
      width: 100%;
    }
    .v-toolbar{
      // width: 100%;
      z-index: 999;
      margin: 0;
      .v-toolbar__title{
        margin-right: 10px;
      }
      .v-toolbar__content{
        padding: 0 5px!important;
      }
      .layout{
        flex: 1 1 auto;
        flex:90;
        // width: 100%;
      }
      .v-input--selection-controls{
        .v-input__slot{
           margin: 0;
         }
        .v-messages{
          display: none;
        }
      }
    }
    .theme--light.v-btn-toggle{
      background: transparent;
      .v-btn:before{display: none}

    }
    .v-btn-toggle--selected{
      box-shadow: none!important;
    }


  .video-js .vjs-menu-button-inline.vjs-slider-active,.video-js .vjs-menu-button-inline:focus,.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
      width: 10em
  }

  .vjs-ended .vjs-loading-spinner { display: none; }
  .video-js{
    .vjs-controls-disabled .vjs-big-play-button {
      display: none!important
    }
    .vjs-control {
      width: 3em
    }

    .vjs-menu-button-inline:before {
      width: 1.5em
    }

    .vjs-menu-button-inline .vjs-menu {
      left: 3em
    }
    &.vjs-paused.vjs-has-started{
      .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button{
        display: block;
      }
    }
    &.vjs-paused .vjs-big-play-button {
      display: block;
    }

    .vjs-tip {
      font-size: 14px;
      transform: translateX(10px);
      font-weight: bold;
      .vjs-tip-arrow{
      }
    }

  }


  .video-js .vjs-load-progress div, .vjs-seeking .vjs-big-play-button, .vjs-waiting .vjs-big-play-button {
      display: none!important
  }

  .video-js .vjs-mouse-display:after,.video-js .vjs-play-progress:after {
      padding: 0 .4em .3em
  }

  .video-js.vjs-ended .vjs-loading-spinner {
      display: none;
  }

  .video-js.vjs-ended .vjs-big-play-button {
      display: block !important;
  }

  .video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button,.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
      display: block
  }

  .video-js .vjs-big-play-button {
      top: 50%!important;
      left: 50%!important;
      margin-left: -1.5em;
      margin-top: -1em
  }

  .video-js .vjs-big-play-button {
      background-color: rgba(0,0,0,1);
      font-size: 3em;
      border-radius: 7%;
      height: 1.8em !important;
      line-height: 1.8em !important;
      margin-top: -0.9em !important
  }

  .video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
      // background-color: #cc181e
      background: #FEAC5E!important;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important;  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .video-js .vjs-loading-spinner {
      border-color: #4BC0C8;
  }

  .video-js .vjs-control-bar2 {
      background-color: #000000
  }

  .video-js .vjs-control-bar {
      background-color: rgba(0,0,0,0.3) !important;
      color: #ffffff;
      font-size: 12px;
      height: 60px;
  }
  .video-js .vjs-play-progress:before {
    font-size: 12px;
    line-height: 12px;
    position: absolute;
    right: -0.5em;
    top: -1px;
    z-index: 1000;
    // z-index: 1;
  }
  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 2.4em;
    line-height: 60px;
  }
  .vjs-progress-holder.vjs-slider.vjs-slider-horizontal{
    height: 10px;
  }

  .video-js .vjs-play-progress,.video-js  .vjs-volume-level {
      background-color: #4BC0C8;
  }
  .vjs-playback-rate .vjs-playback-rate-value{
    line-height: 60px;
  }


  .video-js .vjs-load-progress {
      background: rgba(255,255,255,0.3);
  }
  .video-js .vjs-control.vjs-time-control {
      width: 60px;
  }
  .vjs-remaining-time-display{
    line-height: 60px;
    font-weight: bold;
    font-size: 16px;
  }

  }


</style>
